import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface StepSignatureProps {
  submit:any;
  loading: boolean;

}

const StepSignature:any  = ({
  onClick
}: {
 onClick?: () => void

}) => {
  const { t } = useTranslation();
  return (
    <div className="d-flex align-items-center justify-content-center m-auto height">
      <form className="main-container ">
        <div className="userHeading" style={{background: "#EC5800"}}>
          <h2 className="textcolor">{t("End_Tour")}</h2>
        </div>
        <div className='p-3'>
          <h6 className="mb-3">{t('Signature_requested_here')}</h6>
          <div className='d-flex align-items-center justify-content-center'>
            <div className="submitIcon mt-2" onClick={onClick} style={{background: "#EC5800"}}>
            <FontAwesomeIcon
              icon={faPencil}
              height={25}
              width={25}
              color={'white'}
              onClick={onClick}
            />
          </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default StepSignature;
