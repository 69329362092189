import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useRef, useState } from 'react'
import Webcam from 'react-webcam'
import Button from './Button'
import Modal from 'react-modal'
import { useTranslation } from 'react-i18next'

const SelectImage = ({
  imageUri,
  closeModal,
  isShowModal,
  isTourEnd,
  showWebcam,
  imgSrc,
}: {
  imageUri: (path: string) => void
  closeModal: (path: boolean) => void
  isShowModal: boolean
  isTourEnd?: boolean
  showWebcam?:any
  imgSrc?:any
}) => {
  const { t } = useTranslation()
  
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  const handleImagePicker = () => {
    const input = document.createElement('input')
    input.type = 'file'
    input.accept = 'image/*'
    input.onchange = (event) => {
      const file = (event.target as HTMLInputElement).files?.[0]
      if (file) {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onloadend = () => {
          const selectedImageSrc = reader.result
          if (typeof selectedImageSrc === 'string') {
            imageUri(selectedImageSrc)
            closeModal(false)
          }
        }
      }
    }
    input.click()
  }


  return (
    <>
      {isTourEnd ? (
        <>
          <div className="d-flex align-items-center justify-content-end">
            <FontAwesomeIcon
              icon={faTimes}
              className="crossIcon"
              onClick={() => closeModal(false)}
              color="#027a79"
            />
          </div>
          <div className="d-flex align-items-center justify-content-center flex-column clock mt-5">
            <Button
              title={isMobile ? t('Choose_Gallery') : t('Choose_File')}
              backgroundColor={'white'}
              border={'2px solid #027a79'}
              onButtonClick={handleImagePicker}
              width="160px"
              style={{ paddingLeft: '0px', paddingRight: '0px' }}
            />
            <Button
              backgroundColor={'white'}
              border={'2px solid #027a79'}
              onButtonClick={showWebcam}
              title={t('Capture_Photo')}
              width="160px"
            />
          </div>
         
        </>
      ) : (
        <>
          <Modal
            isOpen={isShowModal}
            onRequestClose={() => closeModal(false)}
            style={{
              content: {
                height: 240,
                width: 250,
                margin: 'auto',
                padding: 10
              }
            }}>
            <div className="d-flex align-items-center justify-content-end">
              <FontAwesomeIcon
                icon={faTimes}
                className="crossIcon"
                onClick={() => closeModal(false)}
                color="#027a79"
              />
            </div>
            <div className="d-flex align-items-center justify-content-center flex-column clock mt-5">
              <Button
                title={isMobile ? t('Choose_Gallery') : t('Choose_File')}
                backgroundColor={'white'}
                border={'2px solid #027a79'}
                onButtonClick={handleImagePicker}
                width="160px"
                style={{ paddingLeft: '0px', paddingRight: '0px' }}
              />
              <Button
                backgroundColor={'white'}
                border={'2px solid #027a79'}
                onButtonClick={showWebcam} 
                title={t('Capture_Photo')}
                width="160px"
              />
            </div>
            {/* {imgSrc ? (
              <img src={imgSrc} alt="Captured" />
            ) : null} */}
             
          </Modal>
        
          
        </>
      )}
    </>
  )
}

export default SelectImage
