import {
  BASE_URL,
  GET_REQUEST,
  HEADER,
  PATCH_REQUEST,
  POST_REQUEST,
  PUBLIC_TOKEN,
  DELETE_REQUEST
} from "../constant/constants";

export const getRequest = async (additionalUrl: string, token?: string) => {
  try {
    const response = await fetch(BASE_URL + additionalUrl, {
      method: GET_REQUEST,
      headers: { ...HEADER, "x-access-token": token ?? PUBLIC_TOKEN },
    });
    const json = await response.json();
    return json;
  } catch (err) {
    return null;
  }
};

export const postRequest = async <T>(
  BODY: T,
  additionalUrl: string,
  token?: string
) => {

  try {
    const response = await fetch(BASE_URL + additionalUrl, {
      method: POST_REQUEST,
      headers: { ...HEADER, "x-access-token": token ?? PUBLIC_TOKEN },
      body: JSON.stringify(BODY),
    });
    const json = await response.json();
    return json;
  } catch (err) {
    return err;
  }
};

export const patchRequest = async <T>(
  BODY: T,
  additionalUrl: string,
  token?: string
) => {
  try {
    const response = await fetch(BASE_URL + additionalUrl, {
      method: PATCH_REQUEST,
      headers: { ...HEADER, "x-access-token": token ?? PUBLIC_TOKEN },
      body: JSON.stringify(BODY),
    });
    const json = await response.json();
    return json;
  } catch (err) {
    return err;
  }
};
export const deleteRequest = async <T>(BODY: T, additionalUrl: string, token?: string) => {

  try {
    const response = await fetch(
      BASE_URL + additionalUrl,
      {
        method: DELETE_REQUEST,
        headers: { ...HEADER, "x-access-token": token ?? PUBLIC_TOKEN },
        body: JSON.stringify(BODY),
      }
    );
    const json = await response.json();
    if (json.error) {
      alert("some error occured");
    } else {
      return json;
    }
  } catch (error) {
    return error;
  }
};