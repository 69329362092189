import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getGarbageType } from '../services/ApiServices';
import { AuthDetails } from '../libs/authDetails';
import { useTranslation } from 'react-i18next';
import squareImg from '../assets/imagesPng/square.png';
import checkImg from '../assets/imagesPng/tick.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleLeft, faChevronCircleRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import Modal from '../components/Modal';

interface FormData {
  garbageType: number | null;
}

interface GarbageFormProps {
  data: any;
  onNext: (data: any) => void;
  onPrevious: () => void;
  showModal: boolean;
  onSeeMore: () => void;
  onClose: () => void;
}

interface ValidationErrorsType {
  garbageType?: string;
}

interface GarbageType {
  color: string;
  garbage_image: null;
  garbage_type: string;
  garbage_type_id: number;
  name: string;
}

const GarbageForm: React.FC<GarbageFormProps> = ({
  data,
  onNext,
  onPrevious,
  showModal,
  onSeeMore,
  onClose
}) => {
  const { t } = useTranslation();
  const { token } = AuthDetails();

  // Initialize formData from localStorage or props
  const initialFormData: FormData = {
    garbageType: data?.garbageType || null,
  };

  const [formData, setFormData] = useState<FormData>(() => {
    const storedFormData = localStorage.getItem('garbageFormData');
    return storedFormData ? JSON.parse(storedFormData) : initialFormData;
  });

  // Fetch garbage types
  const fetchGarbageType = async () => {
    try {
      const data = await getGarbageType(token);
      return data;
    } catch (error) {
      console.error('Error fetching garbage types:', error);
      return [];
    }
  };

  const { data: garbageType = [] } = useQuery('garbageType', fetchGarbageType);

  // Save formData to localStorage
  useEffect(() => {
    localStorage.setItem('garbageFormData', JSON.stringify(formData));
  }, [formData]);

  // Update formData with incoming data prop
  useEffect(() => {
    setFormData((prevFormData: FormData) => ({
      ...prevFormData,
      garbageType: data?.garbageType || prevFormData.garbageType
    }));
  }, [data]);

  // Validation state
  const [validationErrors, setValidationErrors] = useState<ValidationErrorsType>({
    garbageType: ''
  });

  // Handle form submission
  const handleSubmit = () => {
    const errors: ValidationErrorsType = {};
    if (formData.garbageType === null) {
      errors.garbageType = t('select_garbage_type');
    } else {
      errors.garbageType = '';
    }

    setValidationErrors(errors);

    const hasErrors = Object.values(errors).some((error) => error !== '');
    if (!hasErrors) {
      onNext(formData);
    }
  };

  // Handle checkbox change
  const handleCheckboxChange = (id: number) => {
    setFormData((prevFormData: FormData) => ({
      ...prevFormData,
      garbageType: prevFormData.garbageType === id ? null : id
    }));

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      garbageType: formData.garbageType !== null ? '' : prevErrors.garbageType
    }));
  };

  return (
    <div className="d-flex align-items-center justify-content-center m-auto height">
      <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }} className="main-container">
        <div className="userHeading">
          <h6 className="textcolor">{t('Which_faction')}</h6>
        </div>
        <div className="flex-column d-flex p-3 clock">
          <div className="d-flex flex-column gap-2">
            {Array.isArray(garbageType) &&
              garbageType.map((type) => (
                <div
                  key={type.garbage_type_id}
                  className="d-flex justify-content-between align-items-center">
                  <label htmlFor={`${type.garbage_type_id}Checkbox`} className='textFont'>
                    {type.garbage_type}
                  </label>
                  <div
                    onClick={() => handleCheckboxChange(type.garbage_type_id)}>
                    <div className="checkbox-container">
                      <img
                        src={squareImg}
                        alt="Square"
                        className="checkbox-image"
                      />
                      {formData.garbageType === type.garbage_type_id && (
                        <img
                          src={checkImg}
                          alt="Check"
                          className="check-image"
                        />
                      )}
                    </div>
                  </div>
                </div>
              ))}
            {/* Uncomment if needed
            {garbageType?.length > 3 && (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  onSeeMore();
                }}
                className="btn btn-link">
                See More
              </button>
            )}
            */}
          </div>
          {validationErrors.garbageType && (
            <span className="error-message">
              {validationErrors.garbageType}
            </span>
          )}
          {showModal && (
            <Modal
              onClose={onClose}
              contentWidth={'30%'}
              styleModal={{ position: 'absolute' }}
              style={{ padding: '0px' }}>
              <div
                className="modal-header justify-content-end"
                style={{ paddingBottom: '0px 10px' }}>
                <FontAwesomeIcon
                  icon={faTimes}
                  className="close"
                  onClick={onClose}
                />
              </div>
              <div className="d-flex flex-column gap-2 p-3">
                {garbageType.slice(3).map((type: GarbageType) => (
                  <div
                    key={type.garbage_type_id}
                    className="d-flex justify-content-between align-items-center">
                    <label htmlFor={`${type.garbage_type_id}Checkbox`} className='textFont'>
                      {type.garbage_type}
                    </label>
                    <div
                      onClick={() => handleCheckboxChange(type.garbage_type_id)}
                    >
                      <div className="checkbox-container">
                        <img
                          src={squareImg}
                          alt="Square"
                          className="checkbox-image"
                        />
                        {formData.garbageType === type.garbage_type_id && (
                          <img
                            src={checkImg}
                            alt="Check"
                            className="check-image"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Modal>
          )}
        </div>
        <div
          className="d-flex align-items-center justify-content-center mb-3 clock"
          style={{ gap: 45 }}>
          <FontAwesomeIcon
            icon={faChevronCircleLeft}
            color="#027a79"
            className="backIcon"
            onClick={onPrevious}
          />
          <FontAwesomeIcon
            icon={faChevronCircleRight}
            color="#027a79"
            className="backIcon"
            onClick={handleSubmit}
          />
        </div>
      </form>
    </div>
  );
};

export default GarbageForm;
