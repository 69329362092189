import { faChevronCircleLeft, faChevronCircleRight, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from './Button'
import DigitalSignature from './DigitalSignater'
import Modal from './Modal'
import SelectImage from './SelectImage'
import Loader from 'react-js-loader'

interface PauseTime {
  end_time: string;
  start_time: string;
}

const DeleteAlert = ({
  onClose,
  onDelete,
  message,
  istQuestion,
  isTourEnded = false,
  PassengerName,
  handleChangeName,
  mileage,
  handleChange,
  dumped,
  handleDumpChange,
  perKm,
  pauseFrom,
  pauseTo,
  handlePerKmChange,
  fuel,
  handleFuelChange,
  setDamagedPicture,
  submit,
  imageUri,
  errorName,
  errorMileage,
  errorDumped,
  errorFuel,
  errorPerKm,
  submitDisabled,
  emptySubmit,
  emptyFuel,
  dumpDisabled,
  nameDisabled,
  emptyName,
  fuelDisabled,
  emptyMileage,
  mileageDisabled,
  loading,
  onKeyDown,
  emptyDump,
  emptyPerKm,
  perKmDisabled,
  addBreak,
  breaks,
  handlePauseTimeChange,
  handleEndTimeChange,
  handleRemoveBreak,
  firstQuestion,
  secondQuestion,
  onPrevious
}: {
  message?: string
  onClose?: () => void
  onDelete?: () => void
  istQuestion?: string
  isTourEnded?: boolean
  PassengerName?: string
  perKm?: string
  pauseFrom?: string
  pauseTo?: string
  handlePerKmChange?: any;
  handleChangeName?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  mileage?: string
  emptyName?: React.CSSProperties
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  dumped?: string
  onPrevious?:any
  emptySubmit?: React.CSSProperties
  emptyFuel?: React.CSSProperties
  emptyPerKm?: React.CSSProperties
  emptyMileage?: React.CSSProperties
  handleDumpChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  fuel?: string
  handleFuelChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  submit?: any
  setDamagedPicture?: string
  imageUri: (path: string) => void;
  errorName?: string
  errorMileage?: string
  errorDumped?: string
  errorPerKm?: string
  errorFuel?: string
  handleFuleButton?: () => void
  submitDisabled?: boolean
  dumpDisabled?: string | boolean | undefined
  perKmDisabled?: string | boolean | undefined
  emptyDump?: React.CSSProperties
  nameDisabled?: string | boolean | undefined
  fuelDisabled?: string | boolean | undefined
  mileageDisabled?: string | boolean | undefined
  loading?: boolean
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>
  addBreak?: any,
  handleRemoveBreak?: any,
  breaks: PauseTime[];
  handlePauseTimeChange?: any
  handleEndTimeChange?: any
  firstQuestion?:any;
  secondQuestion?:any
}) => {
  const { t } = useTranslation()
  const [showInputs, setShowInputs] = useState(false)
  const [finalMileage, setFinalMileage] = useState(false)
  const [showDumpQuestion, setShowDumpQuestion] = useState(false)
  const [showFuelPerKm, setShowFuelPerKm] = useState(false)
  const [showDieselQuestion, setShowDieselQuestion] = useState(false)
  const [showDamageQuestion, setShowDamageQuestion] = useState(false)
  const [showPauseTimes, setShowPauseTimes] = useState(false)
  const [showSelectImage, setShowSelectImage] = useState(false)
  const [noDamaged, setNoDamaged] = useState(false)
  const [damaged, setDamaged] = useState(false)
  const [signatureData, setSignatureData] = useState<string | null>(null)
  const [signatureError, setSignatureError] = useState(false)
  const [selectedDriver, setSelectedDriver] = useState<string>('');
 const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    submit();
  };
  const handleSignatureSaved = (signatureDataURL: string | null) => {
    if (signatureDataURL === '') {
      setSignatureError(true)
    } else {
      setSignatureError(false)
      setSignatureData(signatureDataURL)
    }
  }
  const modalHeight = isTourEnded
    ? signatureData || noDamaged || setDamagedPicture
      ? 'auto'
      : 'auto'
    : 'auto'
  useEffect(() => {
    if (
      istQuestion === 'Yellow bag garbage truck' ||
      istQuestion === 'Organic waste garbage truck' ||
      istQuestion === 'Paper, cardboard, cardboard garbage trucks' ||
      istQuestion === 'Emptyer'
    ) {
      setShowInputs(true)

    } else {
      setFinalMileage(true)
      setShowInputs(false)
    }
  }, [istQuestion])
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>, nextStep: () => void) => {
    if (event.key === 'Enter') {
      nextStep();
    }
  }
  useEffect(() => {
    const storedDriver = localStorage.getItem('selectedDriver');
    if (storedDriver) {
      setSelectedDriver(storedDriver);
    }
  }, [selectedDriver]);
  console.log("selectedDriver",selectedDriver)
const getMessage = () => {
    switch (selectedDriver) {
      case 'Driver':
        return t('If_you_start_another_job');
      case 'Loader':
        return t('Thank_you_your');
      case 'Machine Operator':
        return t('Thank_you_your');
     
    }
  };

  return (
    <>

        {isTourEnded ? (
          <>
            {!showDumpQuestion &&
              !showDieselQuestion &&
              !showDamageQuestion &&
              !noDamaged ? (
             
               <> {showInputs && firstQuestion ? (
                  <>
                  <div className="userHeading">
            <h5 className="textcolor">{t('Passenger_name')}</h5>
          </div>
                    
                    <input
                      type="text"
                      className="inputform w-100"
                      placeholder={t('Passenger_name')}
                      value={PassengerName}
                      onChange={handleChangeName}
                      required={true}
                      onKeyDown={(e) => handleKeyPress(e, () => {setFinalMileage(true)
                        setShowInputs(false)})}
                    />
                    {errorName && (
                      <span className="error-message">{errorName}</span>
                    )}
                  </>
                ) : finalMileage && firstQuestion ? (
                  <div className="flex-column d-flex ">
                     <div className="userHeading">
            <h5 className="textcolor">{t('Mileage')}</h5>
          </div>
          <div className="p-3">
              <div className="d-flex flex-row  mt-1 mb-2 flex-column">
                <label className="textFont">{t('finalMileage')}</label>
                      <input
                        type="number"
                        placeholder={t('finalMileage')}
                        className="inputform "
                        style={{ width: '100%' }}
                        value={mileage}
                        onChange={handleChange}
                        onKeyDown={(e) => handleKeyPress(e, () => setShowDumpQuestion(true))}
                      />
                      </div>
                    </div>
                    
                  </div>
                ) : null}

                {((showInputs || finalMileage) && firstQuestion) && (
                  <div className="d-flex flex-column align-items-center justify-content-center mt-2">
                    <div
          className="d-flex align-items-center justify-content-center  mb-3 "
          style={{ gap: 45 }}>
          <FontAwesomeIcon
            icon={faChevronCircleLeft}
            color="#027a79"
            className=" backIcon"
            onClick={() => onPrevious()}
          />

          <FontAwesomeIcon
            icon={faChevronCircleRight}
            color="#027a79"
            className=" backIcon"
            onClick={(event) => {
              !showInputs && finalMileage ? setShowDumpQuestion(true)
                :
                setFinalMileage(true)
              setShowInputs(false)}}
          />
        </div>
                    {/* <Button
                      title={t('Next')}
                      onButtonClick={(event) => {
                 
                        !showInputs && finalMileage ? setShowDumpQuestion(true)
                          :
                          setFinalMileage(true)
                        setShowInputs(false)}
                      }
                      backgroundColor="#027a79"
                      color="white"
                      style={
                        finalMileage
                          ? emptyMileage
                          : showInputs
                            ? emptyName
                            : { backgroundColor: '#027a79' }
                      }
                      disabled={nameDisabled && mileageDisabled}
                    /> */}
                  </div>
                )}
          
              </>
            ) : !showFuelPerKm  || secondQuestion ? (
              <>
              
               
                <div className="d-flex flex-column ">
                <div className="userHeading">
            <h5 className="textcolor">{t('how_dumped')}</h5>
          </div>
          <div className="p-3">
              <div className="d-flex flex-row  mt-1 mb-2 flex-column">
                <label className="textFont">{t('Dump_count')}</label>
                  <input
                    type="text"
                    placeholder={t('Dump_count')}
                    className="inputform"
                    value={dumped}
                    onChange={handleDumpChange}
                    onKeyDown={(e) => handleKeyPress(e, () => setShowFuelPerKm(true))}
                  />
                
                  {errorDumped && (
                    <span className="error-message">{errorDumped}</span>
                  )}
                  </div></div>
                    <div className="d-flex flex-column align-items-center justify-content-center mt-2">
                    <div
          className="d-flex align-items-center justify-content-center  mb-3 "
          style={{ gap: 45 }}>
          <FontAwesomeIcon
            icon={faChevronCircleLeft}
            color="#027a79"
            className=" backIcon"
            onClick={() => onPrevious()}
          />

          <FontAwesomeIcon
            icon={faChevronCircleRight}
            color="#027a79"
            className=" backIcon"
            onClick={(event) => {setShowFuelPerKm(true)}}
          />
        </div>
                  </div>
                  {/* <div className="d-flex flex-column align-items-center justify-content-center mt-2">
                    <Button
                      title={t('Next')}
                      onButtonClick={(event) => {setShowFuelPerKm(true)}}
                      backgroundColor="#027a79"
                      color="white"
                      style={emptyDump}
                      disabled={dumpDisabled}
                    />
                  </div> */}
                </div>
              </>
            ) : !showPauseTimes ? (<>
              <h6 className="pt-2 pb-2" style={{ fontSize: '16px' }}>
                {t('FuelKilometers')}
              </h6>
              <div className="d-flex  flex-column pb-2">
                <div className=" d-flex  flex-row w-100">
                  <input
                    type="text"
                    placeholder={t('FuelKilometers')}
                    style={{ width: '75%' }}
                    className="inputform "
                    onKeyDown={(e) => handleKeyPress(e, () => setShowPauseTimes(true))}
                    value={perKm}
                    onChange={handlePerKmChange}

                  />{' '}
                  <p className="m-2"> l/100km</p>
                </div>
                {errorPerKm && (
                  <span className="error-message">{errorPerKm}</span>
                )}
                <div className="d-flex flex-column align-items-center justify-content-center mt-2">
                  <Button
                    title={t('Next')}
                    onButtonClick={() =>   setShowPauseTimes(true)}
                    backgroundColor="#027a79"
                    color="white"
                    style={emptyPerKm}
                    disabled={perKmDisabled}
                  />
                </div>
              </div></>) : !showDieselQuestion ? (
                <div className="d-flex  flex-column mt-1">
                  <h4 className="pt-2 pb-2 text-left mb-2" style={{ fontSize: '16px' }}>
                    {t('Breaks')}:
                  </h4>
                  <div className="d-flex align-items-center justify-content-center pauseInput w-100 mw-100 clock mb-3 flex-column">
                    {breaks.map((PauseTime: PauseTime, index: number) => (
                      <div className="d-flex flex-row clock" style={{ width: '100%' }} key={index}>
                        <div className="w-50" style={{ height: '75px' }}>
                          <h6 className="col-12 inputHeading">{t('PauseFrom')}</h6>
                          <input
                            type="time"
                            className="inputform w-100"
                            style={{ background: 'white' }}
                            value={PauseTime.end_time}
                            onChange={(e) =>
                              handlePauseTimeChange(index, e.target.value, 'end_time')
                            }
                           
                          />
                        </div>
                        <div className="w-50" style={{ height: '75px' }}>
                          <h6 className="w-25 inputHeading">{t('PauseTo')}</h6>
                          <input
                            type="time"
                            className="inputform w-100"
                            style={{ background: 'white' }}
                            value={PauseTime.start_time}
                            onChange={(e) =>
                              handleEndTimeChange(index, e.target.value, 'start_time')
                            }
                          
                          />
                        </div>
                        <div className="d-flex align-items-center mt-2">
                          <FontAwesomeIcon
                            icon={faTimes}
                            className="iconCheck"
                            onClick={() => handleRemoveBreak(index)}
                          />
                        </div>
                      </div>
                    ))}

                    <Button onButtonClick={addBreak} title={t('AddBreakPoint')} backgroundColor="#027a79"
                      color="white" />
                  </div>
                  <div className="d-flex flex-column align-items-center justify-content-center mt-2">
                    <Button
                      title={t('Next')}
                      onButtonClick={() =>  setShowDieselQuestion(true)}
                      backgroundColor="#027a79"
                      color="white"
                      style={emptyDump}
                      disabled={dumpDisabled}
                    />
                  </div>
                </div>)

              :
              !showDamageQuestion ? (
                <>
                  <h6 className="mb-3">{t('How_diesel')}</h6>
                  <div className="d-flex flex-column">
                    <input
                      type="text"
                      placeholder={t('Diesel_amount')}
                      value={fuel}
                      onChange={handleFuelChange}
                      onKeyDown={(e) => handleKeyPress(e, () => setShowDamageQuestion(true))}
                      className="inputform"
                    />
                    {errorFuel && (
                      <span className="error-message">{errorFuel}</span>
                    )}
                    <div className="d-flex flex-column align-items-center justify-content-center mt-2">
                      <Button
                        title={t('Next')}
                        disabled={fuelDisabled}
                        onButtonClick={() => setShowDamageQuestion(true)}
                        backgroundColor="#027a79"
                        color="white"
                        style={emptyFuel}
                      />
                    </div>
                  </div>
                </>
              ) : !damaged && !noDamaged ? (
                <>
                  <h6 className="text-center mb-3">{t('Was_damage')}</h6>
                  <div
                    className="d-flex flex-column align-items-center"
                    style={{ gap: '10px' }}>
                    <div className="d-flex align-items-center flex-row justify-content-center gap-4 mt-3">
                      <div className="choose-container">
                        <p onClick={() => setDamaged(true)}>{t('Yes')}</p>
                      </div>
                      <div className="choose-container">
                        <p onClick={() => setNoDamaged(true)}>{t('No')}</p>
                      </div>
                    </div>
                  </div>
                </>
              ) : damaged && !noDamaged ? (
                <div className="d-flex align-items-center justify-content-center flex-column">
                  {setDamagedPicture && (
                    <img
                      src={setDamagedPicture}
                      alt="Selected"
                      style={{ maxWidth: '100%', maxHeight: '200px' }}
                    />
                  )}
                  {showSelectImage && (
                    <Modal

                      onClose={() => setShowSelectImage(false)}>
                      <SelectImage
                        imageUri={imageUri}
                        isTourEnd={true}
                        closeModal={() => {
                          setShowSelectImage(false)
                        }}
                        isShowModal={true}
                      />
                    </Modal>
                  )}
                  <div className="d-flex mt-3 clock1">
                    <Button
                      onButtonClick={() => setShowSelectImage(true)}
                      title={t('Select_Image')}
                      backgroundColor="#027a79"
                      color="white"
                    />
                    <Button
                      onButtonClick={() => setNoDamaged(true)}
                      title={t('Next')}
                      backgroundColor={setDamagedPicture ? '#027a79' : '#ccc'}
                      color="white"
                      width="120px"
                      disabled={!setDamagedPicture}
                    />
                  </div>
                </div>
              ) : (
                <>
                  <h6 className="mb-3">{t('Signature_requested_here')}</h6>
                  <DigitalSignature
                    onSignatureSaved={handleSignatureSaved}
                    setValidationError={setSignatureError}
                    validationError={signatureError}
                  />

                  <div className="d-flex align-items-center justify-content-center mb-1 mt-3 clock">
                    {loading ? (
                      <Loader
                        type="bubble-top"
                        bgColor={'#027a79'}
                        color={'#027a79'}
                        size={50}
                      />
                    ) : (
                      <>
                        <button
                          className="buttonContainer"
                          onClick={submit}
                          type="button"
                          color="white"
                          
                          disabled={submitDisabled}
                          style={
                            !signatureData ||
                              !!errorMileage ||
                              !!errorDumped ||
                              !!errorPerKm ||
                              !!errorFuel
                              ? {
                                pointerEvents: 'none',
                                opacity: 0.5,
                                background: 'grey',
                                color: 'white'
                              }
                              : emptySubmit
                          }>
                          {t('Submit')}
                        </button>
                      </>
                    )}
                  </div>
                </>
              )}
          </>
        ) : (
          <>
           <Modal
      onClose={onClose}
      contentWidth={'35%'}
      contentHeight={modalHeight}
      style={{ margin: 'auto', paddingTop: signatureData ? '25px' : '10px' }}>
      {/* <div className="d-flex align-items-center flex-row justify-content-end">
        <FontAwesomeIcon icon={faTimes} className="close" onClick={onClose} />
      </div> */}
      <div className="p-2 d-flex flex-row justify-content-center flex-column">
      <form onSubmit={handleSubmit}>
            <div
              className="d-flex align-items-center flex-column"
              style={{ gap: '10px' }}>
                    <h4>{getMessage()}</h4>
              <p style={{ textAlign: 'center' }} className="mb-4">
                {message}
              </p>
            </div>
            <div className="d-flex align-items-center justify-content-center flex-row modalButton clock">
              <Button
                title={t('Yes')}
                onButtonClick={onDelete}
                backgroundColor={'#027a79'}
                color={'white'}
                width={'120px'}
              />
              <Button
                onButtonClick={onClose}
                title={t('No')}
                backgroundColor={'#027a79'}
                color={'white'}
                width={'120px'}
              />
            </div>
            </form> </div>
            </Modal>
          </>
        )}
    </>
  )
}

export default DeleteAlert
