import React, { useState, useRef } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import { MdOutlineSaveAs } from 'react-icons/md'
import { MdOutlineClear } from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useFileUpload from '../hooks/useFileUpload'
import { AuthDetails } from '../libs/authDetails'
import Loader from 'react-js-loader'
import { CurrentFormData, driverCardData, DriverDetailFormData, fqnCardDetails, GarbageFormData, licenseFormData, LoactionFormData, mileageData, StartAndPauseData, SubmitFormData, TripAndVehicleTypeData } from '../types/steppertype';
import { faTimes } from '@fortawesome/free-solid-svg-icons'

interface DigitalSignatureProps {
  onSignatureSaved: (signatureDataURL: string | null, formatDate?: Date) => void
  validationError: boolean
  setValidationError: (value: boolean) => void
  onclick?:any
  selectedDriver?:any
  isAdmin?:any
}

const DigitalSignature = ({
  onSignatureSaved,
  validationError,
  setValidationError,
  selectedDriver,
  isAdmin,
  onclick
}: DigitalSignatureProps) => {
  const sigCanvas = useRef<SignatureCanvas>(null)
  const [showClearButton, setShowClearButton] = useState(true)
  const [isCanvasEnabled, setIsCanvasEnabled] = useState(true)
  const { t } = useTranslation()
  const { username } = AuthDetails()
  const [isLoading, setIsLoading] = useState(false)
  const [showSubmitButton, setShowSubmitButton] = useState(true)
  const [signatureImage, setSignatureImage] = useState<any>(
    localStorage.getItem('signatureDataURL')
  )
  const { UploadFile } = useFileUpload()
  const handleClear = () => {
    if (sigCanvas.current) {
      sigCanvas.current.clear()
      setSignatureImage(null)
      setShowClearButton(true)
      setShowSubmitButton(true)
      setIsCanvasEnabled(true)
    }
  }
  let Signatue_image:any = localStorage.getItem("signatureImage")
  const handleSave = async () => {
    if (sigCanvas.current && !sigCanvas.current.isEmpty()) {
      const signatureDataURL = sigCanvas.current.toDataURL('image/png') 
      setIsLoading(true)
  
      try {
        setSignatureImage(signatureDataURL)
        localStorage.setItem("signatureImage",signatureDataURL)
        onSignatureSaved(signatureDataURL) 
        setShowClearButton(false)
        setShowSubmitButton(false)
        setIsCanvasEnabled(false)
        setValidationError(false)
      } catch (error) {
        console.error('Error saving signature:', error)
      } finally {
        setIsLoading(false)
      }
    } else {
      setValidationError(true)
    }
  }
  
console.log("dfbbf",Signatue_image)
  return (
    
      <>
    
      {isLoading && (
        <div className="overlay">
          <Loader
            type="bubble-top"
            bgColor={'#027a79'}
            color={'#027a79'}
            size={50}
          />
        </div>
      )}
       {Signatue_image===null&&<>
      <div className="d-flex align-items-start  justify-content-center">
        <div
          style={{
            borderBottom: '2px solid black',

            height: '130px'
          }}>
        
      
        <>
          {isCanvasEnabled ? (
            <SignatureCanvas
              penColor="black"
              canvasProps={{
                width: 200,
                height: 126,
                className: 'sigCanvas',
                style: { cursor: 'pointer' }
              }}
              ref={sigCanvas}
            />
          ) : (
             Signatue_image && (
              <div className="mt-1 d-flex align-items-center justify-content-center">
                <img src={Signatue_image} alt="Signature" />
              </div>
            )
          )}</>
         <> {validationError && (
            <p className="error-message" >{t('save_Signature')}</p>
          )}</>
        </div>
      </div>
   
      <div
        className="d-flex align-items-center justify-content-center flex-row  mt-3"
        style={{ gap: '10px', paddingTop: "5px" }}>
        {showClearButton && (
          <MdOutlineClear onClick={handleClear} className="iconSave" />
        )}
        {showSubmitButton && (
          <MdOutlineSaveAs className="iconSave" onClick={handleSave} />
        )}
      </div></>}
      {Signatue_image!==null&&<>
      <div className="d-flex align-items-center justify-content-between">
          <h6>{t('Final_Signature')}</h6>
          {selectedDriver!=="Driver" && isAdmin===1 ?null: <FontAwesomeIcon
              icon={faTimes}
              className="crossIcon"
              color="#027a79"
              onClick={onclick}
            />}</div>
          <div className=" d-flex align-items-center justify-content-center">
            <img src={Signatue_image} alt="Signature" />
          </div>
        </>
    
      }
    </>

  )
}

export default DigitalSignature
