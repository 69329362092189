import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useNavigate } from 'react-router-dom'
import loginIcon from '../assets/imagesPng/tripLogo.png'
import Button from '../components/Button'
import Spinner from '../components/Spinner'
import { apiLogin, apiLoginEmployeeList } from '../services/ApiServices'
import useAuthContext from '../hooks/useAuthContext'
import trip_logo from '../assets/imagesPng/trip-logo.png'
import AuthChecker from '../libs/AuthChecker'
import { updateUserLanguage } from '../services/ApiServices'
import i18n from 'i18next'
import { AuthDetails } from '../libs/authDetails'

interface EmployeeDetails {
  emailId: null
  firstname: string
  lastname: string
  license_number: string
  phonenumber: string
  profile_picture: string
  user_id: number
  username: string
}

const Login = () => {
  const navigation = useNavigate()
  const { t } = useTranslation()
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem('selectedLanguage') || i18n.language
  )
  const [password, setPassword] = useState('')
  const [name, setName] = useState('')
  const [emplyNo, setEmplyNo] = useState('')
  const [employeeUsers, setEmployeeUsers] = useState<EmployeeDetails[]>([])
  const { dispatch } = useAuthContext()
  const [isLoading, setIsLoading] = useState(false)
  const [usernameError, setUsernameError] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [nameError, setNameError] = useState('')
  const [showModal] = useState(false)
  const { token, userId } = AuthDetails()

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      loginUser()

    }
  }
  useEffect(() => {
    fetchEmpoyeeDetails()
  }, [])

  const fetchEmpoyeeDetails = async () => {
    try {
      const response = await apiLoginEmployeeList()
      setEmployeeUsers(response)
    } catch (error) {
      console.error('Error fetching user details:', error)
    }
  }

  const handleEmployeeSelect = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedEmplyNo = event.target.value
    setEmplyNo(selectedEmplyNo)
    setUsernameError('')
    const selectedUser = employeeUsers.find(
      (user) => user.username.toString() === selectedEmplyNo
    )

    if (selectedUser) {
      const capitalize = (str: string) =>
        str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
      const firstName = capitalize(selectedUser.firstname)
      const lastName = capitalize(selectedUser.lastname)
      setName(`${firstName} ${lastName}`)
      setNameError('')
    }
  }
  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value)
    setPasswordError('')
  }

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
    setNameError('')
  }
  async function hashPassword(password: string): Promise<string> {
    const encoder = new TextEncoder()
    const data = encoder.encode(password)
    const hashBuffer = await window.crypto.subtle.digest('SHA-256', data)
    const hashArray = Array.from(new Uint8Array(hashBuffer))
    const hashHex = hashArray
    .map((byte) => byte.toString(16).padStart(2, '0'))
    .join('')
    return hashHex
  }
  const loginUser = async () => {
    setIsLoading(true);
    setUsernameError('');
    setPasswordError('');
    setNameError('');

    if (!emplyNo) {
      setUsernameError(t('Employee_required'));
    }
    if (!password) {
      setPasswordError(t('Password_Required'));
    }
    if (!name) {
      setNameError(t('Username_Required'));
    }
    if (!emplyNo || !password || !name) {
      setIsLoading(false);
      return;
    }
    try {
      const hashedPassword = await hashPassword(password);
      const response = await apiLogin({ emplyNo, hashedPassword});
      if (response.isSuccessful) {
        localStorage.setItem('user', JSON.stringify(response));
        localStorage.setItem('isAuthenticated', JSON.stringify(true));
        dispatch({ type: 'LOGIN', user: response });

     
      } else {
        setPasswordError(t('does_not_match'));
      }
    } catch (error) {
      console.error('Login failed:', error);
    } finally {
      setIsLoading(false);
    }
  };
  const { state } = useAuthContext()
 const isAuthenticated = state.user;
if (isAuthenticated) {
  return (
    <Navigate 
      to="/form" 
      state={{ showModal: true }} 
      replace={true} 
    />
  );
}
  // AuthChecker()

  return (
    <>
      <div
        className={`d-flex align-items-center w-100 background ${showModal ? 'blurred' : ''
          }`}>
        <div className="d-flex align-items-center justify-content-center image">
          <img className="imgIcon" src={loginIcon} alt="" />
        </div>
        <div className="loginContainer">
          <div className="image-container">
            <img src={trip_logo} alt="Trip Logo" />
          </div>
          <div className="d-flex justify-content-center flex-column login_main_div">
            <h4 className="welcome">{t('welcome')}</h4>
            <div style={{ height: '80px' }}>
              <div className="d-flex justify-content-center flex-column align-items-center mt-4">
                <select
                  className="input"
                  style={{ padding: '10px 10px' }}
                  id="serviceDropdown"
                  name="serviceType"
                  value={emplyNo}
                  onChange={handleEmployeeSelect}
                  required>
                    <option value="" disabled>{t('userName')}</option>
                  {employeeUsers?.map((type) => (
                    <option key={type.username} value={type.username}>
                      {type.username}
                    </option>
                  ))}
                </select>
              </div>
              {usernameError && <div className="error">{usernameError}</div>}
            </div>
            <div style={{ height: '80px' }}>
              <div className="d-flex flex-column align-items-center mt-4">
                <input
                  type="text"
                  placeholder={t('First_Name')}
                  className="input"
                  onKeyDown={handleKeyPress}
                  value={name}
                  onChange={handleNameChange}
                />
              </div>
              {nameError && <div className="error">{nameError}</div>}
            </div>
            <div style={{ height: '80px' }}>
              <div className="d-flex justify-content-center flex-column align-items-center mt-4">
                <input
                  type="password"
                  placeholder={t('password')}
                  className="input"
                  value={password}
                  onChange={handlePasswordChange}
                  onKeyDown={handleKeyPress}
                />
              </div>
              {passwordError && <div className="error" style={{marginRight:"10%"}}>{passwordError}</div>}
            </div>
            {isLoading ? (
              <div
                style={{ height: '49px' }}
                className="d-flex justify-content-center flex-column align-items-center mt-4">
                <Spinner color="white" />
              </div>
            ) : (
              <div className="d-flex justify-content-center flex-column align-items-center mt-4">
                <Button
                  title={t('login')}
                  onButtonClick={()=>loginUser()}

                  style={{ fontSize: 20 }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default Login
