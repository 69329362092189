import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { AuthDetails } from '../libs/authDetails'
import { apiGetAllUserDetails, apiLoginEmployeeList } from '../services/ApiServices'
import {
  faChevronCircleLeft,
  faChevronCircleRight
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface TripAndVehicleTypeProps {
  data: any;
  onNext: (data: any) => void;
  onPrevious: () => void;
  mileageData: boolean;
  tripAndVehicleTypeData?: boolean;
 
}
interface EmployeeDetails {
  emailId: null
  firstname: string
  lastname: string
  license_number: string
  phonenumber: string
  profile_picture: string
  user_id: number
  username: string
}
const TripAndVehicleType: React.FC<TripAndVehicleTypeProps> = ({
  data,
  onNext,
  onPrevious,
  mileageData,
  tripAndVehicleTypeData,
 
}) => {
  const { t } = useTranslation()
  const { token, is_admin } = AuthDetails()
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [isOperator, setIsOperator] = useState(false);

  const [isSelectedUser, setIsSelectedUser] = useState(true);

  const [selectedDriver, setSelectedDriver] = useState<string>('');
  const [ SeletctAUser, setSeletctAUser] = useState<any>(false);
  const [co_driver, setCo_Driver] = useState<boolean>(() => {
    const savedCoDriver = localStorage.getItem('co_driver');
    return savedCoDriver ? JSON.parse(savedCoDriver) : false;
  });
  const [isDriver, setIsDriver] = useState<boolean>(() => {
    const savedCoDriver = localStorage.getItem('co_driver');
    return savedCoDriver ? JSON.parse(savedCoDriver) : false;
  });
  const initialData = () => {
    const savedMileage = localStorage.getItem('mileageData')
    return savedMileage ? JSON.parse(savedMileage) : data || {}
  }
  const [mileage, setMileage] = useState(initialData)
  useEffect(() => {
    if(is_admin===1 && selectedDriver==="Driver"){
      setSeletctAUser(true)
    }
    localStorage.setItem('mileageData', JSON.stringify(mileage))
  }, [mileage,selectedDriver,SeletctAUser])
  const [error, setError] = useState('')

  const validateMileage = (value: string) => {
    const regex = /^\d{2,7}$/
    if (value === '' || regex.test(value)) {
      setError('')
    } else if (!/^\d+$/.test(value)) {
      setError(t('Only_no_allowed'))
    } else {
      setError(t('must_digits'))
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, field: string) => {
    const value = e.target.value
    if (value.length <= 7) {
      setMileage(value)
      validateMileage(value)
    }
    setMileage({ ...mileage, [field]: e.target.value })
  }

  const fetchUserDetails = async () => {
    try {
      const data = await apiGetAllUserDetails(token)
      return data
    } catch (error) {
      console.error('Error fetching user details:', error)
      return []
    }
  }
  const [employeeUsers, setEmployeeUsers] = useState<EmployeeDetails[]>([])

  const [validationErrors, setValidationErrors] = useState({
    cost_center_id: '',
    user_id: ''
  })


  const {
    data: usersData = [] } = useQuery('userDetails', fetchUserDetails)
  const initialFormData = () => {
    const savedData = localStorage.getItem('tripAndVehicleTypeData')
    return savedData ? JSON.parse(savedData) : data || {}
  }
  const [selectedUser, setSelectedUser] = useState('');
  const [handlesChange, setHandleChange] = useState('0');

  const [formDataState, setFormDataState] = useState(initialFormData)
  const storedData = localStorage.getItem('user')
  const parsedData = storedData ? JSON.parse(storedData) : null



  const handleCheckboxChange = (event: { target: { checked: boolean } }) => {
    console.log("event.target",event.target.checked)
    setIsCheckboxChecked(event.target.checked);
    localStorage.setItem('isCheckboxChecked', JSON.stringify(event.target.checked));
    if(event.target.checked){
      setCo_Driver(event.target.checked);   
        setSelectedUser('');
      localStorage.setItem('co_driver', JSON.stringify(event.target.checked));
    }
    else if(!(event.target.checked)){
  
      setCo_Driver(true);  
      localStorage.setItem('co_driver', JSON.stringify(true));
    }
   
  // if(!isCheckboxChecked){
  //   setCo_Driver(true)
  //   localStorage.setItem('co_driver', JSON.stringify(event.target.checked));
  //   console.log("co-driverrrrr",localStorage.getItem('co_driver'))
  // }
  };

  useEffect(() => {
    const storedCoDriver = localStorage.getItem('co_driver');
    if (storedCoDriver) {
      setCo_Driver(JSON.parse(storedCoDriver));
    }
    const storedCheckboxState = localStorage.getItem('isCheckboxChecked');
    if (storedCheckboxState) {
      setIsCheckboxChecked(JSON.parse(storedCheckboxState));
    }
    const storedIsDriver = localStorage.getItem('isdriver');
    if (storedIsDriver) {
      setIsDriver(JSON.parse(storedIsDriver));
    }
  }, []);
  // const handleSelectPassenger = (e: React.ChangeEvent<HTMLSelectElement>) => {
  //   
  //   setValidationErrors({ ...validationErrors, [field]: '' })
  //   localStorage.setItem('OptionalPassenger', e.target.value)
  // }
console.log("handlesChange",handlesChange)
  const handleChangeVehicle = (e: React.ChangeEvent<HTMLSelectElement>, field: string) => {
    
    if (is_admin === 1 && !SeletctAUser) {
     if(selectedDriver === "Machine Operator"){setIsDriver(false)}else{setIsDriver(true)}
      if(isDriver&&isSelectedUser){
        setIsSelectedUser(false)
        setSelectedUser(e.target.value)
        localStorage.setItem('selectedUser', e.target.value);
      }
    else{
      setIsSelectedUser(true)
      setFormDataState({ ...formDataState, [field]: e.target.value });}
      if(selectedDriver !== "Machine Operator") {localStorage.setItem('isdriver',JSON.stringify(true))}
    } else if(is_admin !== 1) {

      setSelectedUser(e.target.value)
      setFormDataState({ ...formDataState, user_id: e.target.value });
      localStorage.setItem('selectedUser', e.target.value);
      localStorage.setItem('isSelectedDriver', e.target.value);
    }
    if(is_admin === 1 && SeletctAUser && selectedDriver === "Driver"){
      setCo_Driver(true)
      if(co_driver&&isSelectedUser){
          setSelectedUser(e.target.value)
          setIsSelectedUser(false)
        localStorage.setItem('selectedUser', e.target.value);
      } else {
        setIsSelectedUser(true)
        setFormDataState({ ...formDataState, [field]: e.target.value });
      }
      localStorage.setItem('co_driver',JSON.stringify(true))
}
// else if(co_driver){
//   setSelectedUser(e.target.value)
//   localStorage.setItem('selectedUser', e.target.value);
// }
    setValidationErrors({ ...validationErrors, [field]: '' })
  }
  useEffect(() => {
    // if (is_admin !== 1) {
    //   handleChangeVehicle(parsedData.id, "user_id")
    // }
   if(is_admin===1 && selectedDriver==="Loader"){
    setIsLoader(true)
   }else if(is_admin===1 && selectedDriver==="Machine Operator"){
    setIsOperator(true)
   }
    localStorage.setItem(
      'tripAndVehicleTypeData',
      JSON.stringify(formDataState)
    )
  }, [formDataState,selectedDriver, is_admin,])
  console.log("formDataState",formDataState)
  let selectedPassenger = localStorage.getItem('OptionalPassenger');
  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault()
    if (tripAndVehicleTypeData) {
      const vehicleTypeError = !formDataState.cost_center_id
        ? t('Vehicle_T_required')
        : ''
      const tripError = !formDataState.user_id && selectedDriver !== "Driver" && is_admin !== 1 && selectedUser === "" ? t('Create_Trip_required') :is_admin === 1&& selectedUser === ""&& !formDataState.user_id&& !isLoader && !isOperator ?t('Create_Trip_required'): is_admin===1&&co_driver&&selectedDriver==="Driver"?"":!formDataState.user_id&& selectedDriver === "Loader"&&!SeletctAUser && is_admin===1&&!isDriver&&isLoader &&!isOperator?t("LoaderRequired"):!formDataState.user_id&& selectedDriver === "Machine Operator"&&!SeletctAUser && is_admin===1 && !isDriver&& isOperator?t("Machinerequired"):!SeletctAUser && is_admin===1 && isDriver&& !selectedUser&&isOperator? t("Create_Trip_required"):!SeletctAUser &&!selectedUser&& is_admin===1 && isDriver&& isLoader? t("Create_Trip_required"):''
      const isAdmin = is_admin === 1
      setValidationErrors({
        cost_center_id: vehicleTypeError,
        user_id: tripError
      })
      // if (isAdmin) {
      if (tripError) {
        /*empty*/
        // alert("jdnb")
      } 
      // else if(tripError && isAdmin && ){}
      else {
        onNext(formDataState)
      }
      // } else {
      //   onNext(formDataState)
      // }
    }
    const hasErrors = Object.values(error).some((error) => error !== '')

    if (mileageData) {
      if (!mileage.start_mileage) {
        setError(t('Mile_is_required'))
        return
      }
      if (!hasErrors) {
        onNext(mileage)
      }
    }
  }

  useEffect(() => {
    fetchEmpoyeeDetails()
  }, [])

  const fetchEmpoyeeDetails = async () => {
    try {
      const response = await apiLoginEmployeeList()
      const filteredUsers = response.filter((user: any) => user.user_id !== parsedData.id);
      setEmployeeUsers(filteredUsers);

    } catch (error) {
      console.error('Error fetching user details:', error)
    }
  }
  useEffect(() => {
    const storedDriver = localStorage.getItem('selectedUser');
    if (storedDriver) {
      setSelectedUser(storedDriver);
    }
  }, []);
  useEffect(() => {
    const storedDriver = localStorage.getItem('selectedDriver');
    if (storedDriver) {
      setSelectedDriver(storedDriver);
    }
  }, []);
  
  const getMessage = () => {
    switch (selectedDriver) {
      case 'Driver':
        return t('WhoIsYourCoDriver');
      case 'Loader':
        return t('WhoIsYourLoader');
      case 'Machine Operator':
        return t('WhoIsYourOperator');
      default:
        return '';
    }
  };

  return (
    <>
      <div
        className="d-flex align-items-center justify-content-center m-auto height"
        style={{ background: '' }}>
        <form onSubmit={handleSubmit} className="main-container">
          <div className="userHeading">
            <h2 className="textcolor">
              {mileageData ? t('starting_mileage') :is_admin === 1 && selectedDriver === "Driver"&& SeletctAUser? t('WhoIsYourDriver') :is_admin !== 1&&selectedDriver==="Loader"?t('WhoIsYourDriver'):is_admin !== 1&&selectedDriver==="Machine Operator"?t('WhoIsYourDriver'): getMessage()}
            </h2>
          </div>
          {mileageData ? (
            <div className="flex-column d-flex p-3  ">
              <h6 className='textFont'>{t('MileageInitial')}</h6>
              <div style={{ height: '60px' }}>
                <input
                  type="text"
                  placeholder={t('starting_mileage')}
                  className="inputform"
                  style={{ width: '100%' }}
                  value={mileage.start_mileage}
                  onChange={(e) => handleChange(e, 'start_mileage')}
                  maxLength={7}
                />
                <p className='font-weight-bold mt-1'>{t("Attention")}</p>
                {error && <span className="error-message">{error}</span>}
              </div>
            </div>
          ) : (
            <div className="p-3" style={{height:formDataState.user_id ===undefined ?"":formDataState.user_id !==undefined && is_admin===1?"140px":""}}>

              {is_admin === 1&& !SeletctAUser && selectedDriver !== "Driver"? (
                <>
                  {/* <h6 className="mt-1 textFont">{t('User')}</h6> */}
                  <div className="custom-select mt-3" style={{ height: '45px' }}>
                    <select
                      className="inputform"
                      id="serviceDropdown"
                      name="serviceType"
                      required
                      value={formDataState.user_id}
                      onChange={(e) => handleChangeVehicle(e, 'user_id')}>
                      <option value="">{t('Select')}</option>
                      {Array.isArray(usersData) &&
                        usersData.map((user) => (
                          <option key={user.user_id} value={user.user_id}>
                            {`${user.firstname} ${user.lastname}`}
                          </option>
                        ))}
                    </select>
                    {/* {validationErrors.user_id && (
                      <span className="error-message">
                        {validationErrors.user_id}
                      </span>
                    )} */}
                    <span className="custom-arrow"></span>
                  </div>
                  
                  {isDriver&&  <div className="custom-select mt-3" style={{ height: '45px' }}> 
                       <select
                      className="inputform"
                      id="serviceDropdown"
                      name="serviceType"
                      value={selectedPassenger || selectedUser}
                      onChange={(e) => handleChangeVehicle(e, 'user_id')}
                      disabled={isCheckboxChecked}
                      style={{ opacity: isCheckboxChecked ? 0.3 : '' }}>
                      <option value="">{t('WhoIsYourDriver')}</option>
                      {employeeUsers?.filter((type) => type.user_id !== Number(formDataState.user_id)).map((type) => (
                        <option key={type.user_id} value={type.user_id}>
                          {type.firstname}{" "}{type.lastname}
                        </option>
                      ))}
                    </select> 
                   
                    <span className="custom-arrow"></span>
                  </div>}
                  {validationErrors.user_id && (
                      <span className="error-message">
                        {validationErrors.user_id}
                      </span>
                    )}
                </>
              ) : is_admin === 1 && selectedDriver === "Driver" && SeletctAUser ?
                <>
                  <div className="custom-select mt-3" style={{ height: '45px' }}>
                  <select
                      className="inputform"
                      id="serviceDropdown"
                      name="serviceType"
                      required
                      value={formDataState.user_id}
                      onChange={(e) => handleChangeVehicle(e, 'user_id')}>
                      <option value="">{t('Select')}</option>
                      {Array.isArray(usersData) &&
                        usersData.map((user) => (
                          <option key={user.user_id} value={user.user_id}>
                            {`${user.firstname} ${user.lastname}`}
                          </option>
                        ))}
                    </select>
                     {co_driver && selectedDriver==="Driver" ?
                     <div className="custom-select mt-3" style={{ height: '45px' }}>
                       <select
                      className="inputform"
                      id="serviceDropdown"
                      name="serviceType"
                      value={selectedPassenger || selectedUser}
                      onChange={(e) => handleChangeVehicle(e, 'user_id')}
                      disabled={isCheckboxChecked}
                      style={{ opacity: isCheckboxChecked ? 0.3 : '' }}>
                      <option value="">{t('WhoIsYourCoDriver')}</option>
                      {employeeUsers?.filter((type) => type.user_id !== Number(formDataState.user_id))?.map((type) => (
                        <option key={type.user_id} value={type.user_id}>
                          {type.firstname}{" "}{type.lastname}
                        </option>
                      ))}
                    </select>
                    <span className="custom-arrow"></span>
                    <div className='d-flex align-items-center  gap-2 mt-2 mb-5'>
                    <input type={'checkbox'} className='checkbox' style={{ height: '20px', width: '20px' }} checked={isCheckboxChecked}
                      onChange={handleCheckboxChange}
                      disabled={!!selectedPassenger}></input>
                    <p> {t("Ipassenger")}</p>

                  </div>
                    </div>
                    :null}
                    {validationErrors.user_id && (
                      <span className="error-message">
                        {validationErrors.user_id}
                      </span>
                    )}
                    <span className="custom-arrow"></span>
                  </div>
                </>
                : is_admin!==1 &&<>
                  {/* <h6 className="mt-1 mb-1">{t('User')}</h6> */}
                  <div className="custom-select mt-3" style={{ height: '45px' }}>
                    <select
                      className="inputform"
                      id="serviceDropdown"
                      name="serviceType"
                      value={selectedPassenger || selectedUser}
                      onChange={(e) => handleChangeVehicle(e, 'user_id')}
                      disabled={isCheckboxChecked}
                      style={{ opacity: isCheckboxChecked ? 0.3 : '' }}
                    >
                      <option value="">{t('Select')}</option>
                      {employeeUsers?.map((type) => (

                        <option key={type.user_id} value={type.user_id}>
                          {type.firstname}{" "}{type.lastname}
                        </option>
                      ))}
                    </select>
                    {/* <div className='d-flex align-items-center  gap-2 mt-2'>
                    <input type={'checkbox'} className='checkbox' style={{ height: '20px', width: '20px' }} checked={isCheckboxChecked}
                      onChange={handleCheckboxChange}
                      disabled={!!selectedPassenger}></input>
                    <p> {t("Ipassenger")}</p>

                  </div> */}
                    {validationErrors.user_id && (
                      <span className="error-message">
                        {validationErrors.user_id}
                      </span>
                    )}
                    <span className="custom-arrow"></span>
                    {is_admin !== 1&&selectedDriver==="Driver" &&
                    <div className='d-flex align-items-center  gap-2 mt-2 mb-2'>
                    <input type={'checkbox'} className='checkbox' style={{ height: '20px', width: '20px', }} checked={isCheckboxChecked}
                      onChange={handleCheckboxChange}
                      disabled={!!selectedPassenger}></input>
                    <p> {t("Ipassenger")}</p>

                  </div>}
                  </div>
                </>}
            </div>
          )}
          <div
            className={(isDriver||co_driver)&& selectedDriver==="Driver"&& is_admin===1 ?"d-flex align-items-center justify-content-center mb-3 clock ValidationStyle":"d-flex align-items-center justify-content-center mb-3 clock ValidationStyle1"}  style={{gap: 45 }}>
            <FontAwesomeIcon
              icon={faChevronCircleLeft}
              color="#027a79"
              className=" backIcon"
              onClick={() => onPrevious()}
            />

            <FontAwesomeIcon
              icon={faChevronCircleRight}
              color="#027a79"
              className=" backIcon"
              onClick={handleSubmit}
            />
          </div>
        </form>
      </div>
    </>
  )
}

export default TripAndVehicleType
