import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from './Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleLeft, faChevronCircleRight, faTimes } from '@fortawesome/free-solid-svg-icons';

interface PauseTime {
  end_time: string;
  start_time: string;
}

interface StepPauseTimesProps {
  breaks: PauseTime[];
  handlePauseTimeChange: (index: number, value: string, field: 'start_time' | 'end_time') => void;
  handleEndTimeChange: (index: number, value: string, field: 'start_time' | 'end_time') => void;
  handleRemoveBreak: (index: number) => void;
  addBreak?: () => void;
  onNext: () => void;
  onPrevious: () => void;
  errorDumped?: string;
  handleDumpChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  dumped?: string;
  isDumped: boolean
  totalSelected?:any

}

const StepPauseTimes:any = ({
  breaks,
  handlePauseTimeChange,
  handleEndTimeChange,
  handleRemoveBreak,
  addBreak,
  onNext,
  onPrevious,
  errorDumped,
  handleDumpChange,
  dumped,
  isDumped,
  totalSelected
}:{
  breaks: PauseTime[];
  handlePauseTimeChange: (index: number, value: string, field: 'start_time' | 'end_time') => void;
  handleEndTimeChange: (index: number, value: string, field: 'start_time' | 'end_time') => void;
  handleRemoveBreak: (index: number) => void;
  addBreak?: () => void;
  onNext: () => void;
  onPrevious: () => void;
  errorDumped?: string;
  handleDumpChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  dumped?: string;
  isDumped: boolean
  totalSelected?:any
}) => {
  const { t } = useTranslation();
const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
  if (e.key === 'Enter') {
    e.preventDefault();
    if (onNext) {
      onNext();
    }
  }
};

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); 
  };
  return (
    <div className="d-flex align-items-center justify-content-center m-auto height">
      <form className="main-container" onSubmit={handleSubmit}>
        <div className="userHeading" style={{background: "#EC5800"}}>
          <h2 className="textcolor">{isDumped ? t("dumped_hed") : `${t("pause1")}`}</h2>
        </div>
        <div className="d-flex flex-column mt-1 p-3">
          {isDumped ? (
            <div className="d-flex flex-row  mt-1 mb-2 flex-column">
              <label className="textFont">{t('how_dumped')}</label>
              <input
                type="text"
                placeholder={t('Dump_count')}
                className="inputform"
                value={dumped}
                onChange={handleDumpChange}
                onKeyDown={handleKeyPress}
              />

              {errorDumped && (
                <span className="error-message">{errorDumped}</span>
              )}
              <p className='mt-1 font-weight-bold mb-2'>{t("Dump_Note")}</p>
            </div>
          ) : (

            <>
              <h4 className="pt-2 pb-2 text-left mb-2" style={{ fontSize: '16px' }}>
                {t('Breaks')}:
              </h4>
              <div className="d-flex align-items-center justify-content-center pauseInput w-100 mw-100 clock mb-3 flex-column">
                {breaks.map((pauseTime, index) => (
                  <div className="d-flex flex-row clock" style={{ width: '100%' }} key={index}>
                    <div className="w-50" style={{ height: '75px' }}>
                      <h6 className="col-12 inputHeading">{t('PauseFrom')}</h6>
                      <input
                        type="time"
                        className="inputform w-100"
                        style={{ background: 'white' }}
                        value={pauseTime.start_time}
                        onChange={(e) => handlePauseTimeChange(index, e.target.value, 'start_time')}
                      />
                    </div>
                    <div className="w-50" style={{ height: '75px' }}>
                      <h6 className="w-25 inputHeading">{t('PauseTo')}</h6>
                      <input
                        type="time"
                        className="inputform w-100"
                        style={{ background: 'white' }}
                        value={pauseTime.end_time}
                        onChange={(e) => handleEndTimeChange(index, e.target.value, 'end_time')}
                      />
                    </div>
                    <div className="d-flex align-items-center mt-2">
                      <FontAwesomeIcon
                        icon={faTimes}
                        className="iconCheck"
                        
                        onClick={() => handleRemoveBreak(index)}
                      />
                    </div>
                  </div>
                ))}
                <Button onButtonClick={addBreak} title={`${t("pause")} ${"#"}${totalSelected}`} backgroundColor="#EC5800" color="white" />
              </div>
              <p className='mt-1 font-weight-bold mb-2'>{t("Paused_Time")}
              <span style={{ fontWeight: '900', textDecoration: 'underline' }}>
              {t("Paused_Time1")}</span>
              {t("Paused_Time11")}
              </p>
            </>
          )}
          <div className="d-flex align-items-center justify-content-center mb-3 mt-4" style={{ gap: 45 }}>
            {onPrevious && (
              <FontAwesomeIcon
                icon={faChevronCircleLeft}
                color="#EC5800"
                className="backIcon"
                onClick={onPrevious}
              />
            )}
            <FontAwesomeIcon
              icon={faChevronCircleRight}
              color="#EC5800"
              className="backIcon"
              onClick={onNext}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default StepPauseTimes;
