import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import Modal from './Modal'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import useLogout from '../hooks/useLogout'
import Germany from '../assets/imagesPng/Germany.png'
import USA from '../assets/imagesPng/USA.png'
import Polish from '../assets/imagesPng/Polish.png'
import Russian from '../assets/imagesPng/Russian.png'
import Turkey from '../assets/imagesPng/Turkey.png'
import { updateUserLanguage } from '../services/ApiServices'
import Button from './Button'
import { AuthDetails } from '../libs/authDetails'
import Loader from 'react-js-loader'
const Dropdown = ({ isLogin }: { isLogin?: boolean }) => {
  const [showLanguages, setShowLanguages] = useState(false)
  const navigate = useNavigate()
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem('selectedLanguage') || i18n.language
  )
  const { token, userId } = AuthDetails()
  const { logout } = useLogout()
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation()
  const toggleLanguages = () => {
    setShowLanguages(!showLanguages)
  }

  const handleLanguageSelect = (lang: string) => {
    i18n.changeLanguage(lang)
    localStorage.setItem('selectedLanguage', lang)
    setSelectedLanguage(lang)
    setShowLanguages(false)
  }

  const handleSubmit = () => {
    navigate('/login')
  }
  return (
    <>
      {isLogin ? (
        <div className="d-flex align-items-center justify-content-center flex-column w-100 gap-3 drop-login">
          <div
            className="d-flex align-items-center justify-content-center flex-row gap-4 w-100"
            onClick={() => handleLanguageSelect('en')}>
            <img src={USA} className="flagImage" alt='' />
            <a
              className={selectedLanguage === 'en' ? 'selecte' : 'unSelected'}
              onClick={() => handleLanguageSelect('en')}>
              English
            </a>
          </div>
          <div
            className="d-flex align-items-center justify-content-center flex-row gap-4 w-100"
            onClick={() => handleLanguageSelect('ru')}>
            <img src={Russian} className="flagImage" alt='' />
            <a
              className={selectedLanguage === 'ru' ? 'selecte' : 'unSelected'}
              onClick={() => handleLanguageSelect('ru')}>
              Russian
            </a>
          </div>
          <div
            className="d-flex align-items-center justify-content-center flex-row gap-4 w-100"
            onClick={() => handleLanguageSelect('ger')}>
            <img src={Germany} className="flagImage" />
            <a
              className={selectedLanguage === 'ger' ? 'selecte' : 'unSelected'}
              onClick={() => handleLanguageSelect('ger')}>
              German
            </a>
          </div>
          <div
            className="d-flex align-items-center justify-content-center flex-row gap-4 w-100"
            onClick={() => handleLanguageSelect('pol')}>
            <img src={Polish} className="flagImage" alt='' />
            <a
              className={selectedLanguage === 'pol' ? 'selecte' : 'unSelected'}
              onClick={() => handleLanguageSelect('pol')}>
              Polish
            </a>
          </div>
          <div
            className="d-flex align-items-center justify-content-center flex-row gap-4 w-100"
            onClick={() => handleLanguageSelect('tur')}>
            <img src={Turkey} className="flagImage" alt='' />
            <a
              className={selectedLanguage === 'tur' ? 'selecte' : 'unSelected'}
              onClick={() => handleLanguageSelect('tur')}>
              Turkish
            </a>
          </div>
          <div className="d-flex align-items-center justify-content-center pt-4">
            {isLoading ? (
              <Loader
                type="bubble-top"
                bgColor={'#027a79'}
                color={'#027a79'}
                size={50}
              />
            ) : (
              <Button
                title={t('save')}
                backgroundColor={'#027a79'}
                color={'white'}
                onButtonClick={handleSubmit}
              />
            )}
          </div>
        </div>
      ) : (
        <div className="dropdown-content">
          <div className="dropdown">
            <a onClick={logout} className="w-100">
              {t('Logout')}
            </a>
          </div>
          <div onClick={toggleLanguages}>
            <a className="w-100">{t('changeLanguages')}</a>
            {showLanguages && (
              <Modal
                onClose={() => setShowLanguages(false)}
                contentWidth={'30%'}>
                <div className="d-flex align-items-center justify-content-end w-100">
                  <FontAwesomeIcon
                    icon={faTimes}
                    className="close"
                    onClick={() => setShowLanguages(false)}
                  />
                </div>
                <div className="d-flex align-items-center justify-content-center flex-column w-100 gap-2">
                  <div
                    className="d-flex align-items-center justify-content-center flex-row gap-4 w-100"
                    onClick={() => handleLanguageSelect('en')}>
                    <img src={USA} className="flagImage" alt='' />
                    <a
                      className={
                        selectedLanguage === 'en' ? 'selecte' : 'unSelected'
                      }
                      onClick={() => handleLanguageSelect('en')}>
                      English
                    </a>
                  </div>
                  <div
                    className="d-flex align-items-center justify-content-center flex-row gap-4 w-100"
                    onClick={() => handleLanguageSelect('ru')}>
                    <img src={Russian} className="flagImage" alt='' />
                    <a
                      className={
                        selectedLanguage === 'ru' ? 'selecte' : 'unSelected'
                      }
                      onClick={() => handleLanguageSelect('ru')}>
                      Russian
                    </a>
                  </div>
                  <div
                    className="d-flex align-items-center justify-content-center flex-row gap-4 w-100"
                    onClick={() => handleLanguageSelect('ger')}>
                    <img src={Germany} className="flagImage" alt='' />
                    <a
                      className={
                        selectedLanguage === 'ger' ? 'selecte' : 'unSelected'
                      }
                      onClick={() => handleLanguageSelect('ger')}>
                      German
                    </a>
                  </div>
                  <div
                    className="d-flex align-items-center justify-content-center flex-row gap-4 w-100"
                    onClick={() => handleLanguageSelect('pol')}>
                    <img src={Polish} className="flagImage" />
                    <a
                      className={
                        selectedLanguage === 'pol' ? 'selecte' : 'unSelected'
                      }
                      onClick={() => handleLanguageSelect('pol')}>
                      Polish
                    </a>
                  </div>
                  <div
                    className="d-flex align-items-center justify-content-center flex-row gap-4 w-100"
                    onClick={() => handleLanguageSelect('tur')}>
                    <img src={Turkey} className="flagImage" />
                    <a
                      className={
                        selectedLanguage === 'tur' ? 'selecte' : 'unSelected'
                      }
                      onClick={() => handleLanguageSelect('tur')}>
                      Turkish
                    </a>
                  </div>
                </div>
              </Modal>
            )}
          </div>
        </div>
      )}
    </>
  )
}
export default Dropdown
