import React from 'react'
import StepperForm from '../components/StepperForm'
import AuthChecker from '../libs/AuthChecker'

const Form = () => {
  // AuthChecker()
  return (
    
    <StepperForm />

  )
}

export default Form
