import { useNavigate } from 'react-router-dom'
import useAuthContext from './useAuthContext'
function useLogout() {
  const navigate = useNavigate()
  const { dispatch } = useAuthContext()
  const logout = async () => {
   localStorage.clear()
    dispatch({ type: 'LOGOUT' })
    navigate('/',{replace:true})
  }
  return { logout }
}

export default useLogout
