import React from 'react'

const Button = ({
  title,
  onButtonClick,
  backgroundColor,
  color,
  width,
  border,
  disabled,
  style,
  disableHover
}: {
  title: string
  onButtonClick?: any
  backgroundColor?: string
  color?: string
  width?: string
  border?: string
  disabled?: any
  style?: React.CSSProperties
  disableHover?: boolean
}) => {
  const containerStyle = {
    backgroundColor: backgroundColor,
    color: color,
    width: width,
    border: border,
    ...style
  }
  return (
    <button
      className={`buttonContainer  ${disableHover ? 'no-hover' : ''}`}
      onClick={onButtonClick}
      type="button"
      disabled={disabled}
      style={containerStyle}>
      {title}
    </button>
  )
}

export default Button
