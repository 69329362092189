import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBHyCsbyNdi1mHSxx71tJ2MSwPlQmPsDX8",
  authDomain: "trip-tracker-web.firebaseapp.com",
  projectId: "trip-tracker-web",
  storageBucket: "trip-tracker-web.appspot.com",
  messagingSenderId: "851874794920",
  appId: "1:851874794920:web:0bbaade4cb80cd4a6ca19d",
  measurementId: "G-16JGL408NV"
};

export const app = initializeApp(firebaseConfig);
export const storage = getStorage(app)