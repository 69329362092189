import React, { useEffect, useRef } from 'react'

const Modal = ({
  children,
  onClose,
  contentWidth,
  contentHeight,
  style,
  content,
  styleModal
}: {
  children?: any
  onClose?: () => void
  contentWidth?: string
  contentHeight?: string
  style?: React.CSSProperties
  styleModal?: React.CSSProperties
  content?:any
}) => {
  const modalRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        /*empty*/
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [onClose])
  return (
    <div className="modal" style={styleModal}>
      <div
        className={content?"ModalContent":"modal-content"}
        ref={modalRef}
        style={{
          width: contentWidth,
          height: contentHeight,
          ...style
        }}>
        {children}
      </div>
    </div>
  )
}

export default Modal
