import React from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import Login from '../pages/Login'
import DashBoard from '../pages/DashBoard'
import Admin from '../pages/Admin'
import Form from '../pages/Form'
import TripDetails from '../pages/TripDetails'
import EditTrip from '../pages/EditTrip'
import UserList from '../pages/UserList'
import UserLanguage from '../pages/UserLanguage'
import EndStepperForm from './EndStepperForm'



const RoutesComponents = () => {

  return (
    <Routes>
      <Route path="/dashboard" element={<DashBoard />} />
      <Route path="/form" element={<Form />} />
      <Route path="/admin" element={<Admin />} />
      <Route path="/tripDetails" element={<TripDetails />} />
      <Route path="/editTrip" element={<EditTrip />} />
      <Route path="/userList" element={<UserList />} />
      <Route path="/" element={<UserLanguage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/endform" element={<EndStepperForm/>} />
    </Routes>
  )
}

export default RoutesComponents
