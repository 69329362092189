import React, { useState, useEffect } from 'react'
import {
  apiGetLocationList,
  EditedTrip,
  getVehicleNumber,
  getVehicleType
} from '../services/ApiServices'
import { AuthDetails } from '../libs/authDetails'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useQuery } from 'react-query'
import Button from '../components/Button'
import { useNavigate } from 'react-router-dom'
import Header from '../components/Header'
import Loader from 'react-js-loader'
import AuthChecker from '../libs/AuthChecker'
import { getTripDetails } from '../services/ApiServices'
import { TripDetailsData } from '../types/userList'

interface CarDataType {
  carId: number;
  carNumber: string;
  mark: string;
  status: number;
}
const EditTrip = () => {
  const { state } = useLocation()
  const { t } = useTranslation()
  const { token, userId } = AuthDetails()
  const [isload, setIsLoad] = useState(true);
  const navigate = useNavigate()
  const [tripDetails, setTripDetails] = useState<any>();
  console.log("bfdcbyu",tripDetails)
  const [tripDetailsMileage, setTripDetailsMileage] = useState<string>('');
  const [tripDetailsFaction, setTripDetailsFaction] = useState<string>('');

  const formatDateTime = (datetime: string | number | Date) => {
    if (!datetime) return ''
    const date = new Date(datetime)
    const year = date.getFullYear()

    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')
    const seconds = String(date.getSeconds()).padStart(2, '0')
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
  }


  const tripId = state?.tripId.trip_id
  const [startTime, setStartTime] = useState(
    formatDateTime(state?.tripId.check_in_time)
  )
  const [endTime] = useState(
    formatDateTime(state?.tripId.check_out_time)
  )
  const [location, setLocation] = useState(state?.tripId.location || '')

  const [vehicleType, setVehicleType] = useState(
    state?.tripId.cost_center_id || ''
  )


  const [selectedVehicleNumber, setVehicleNumber] = useState(
    state?.tripId.car_id || ''
  )
  const [postalCode, setPostalCode] = useState(
    state?.tripId.postal_code || ''
  )

  const [totalTime,] = useState(state?.tripId.total_time || '')
  const [loading, setLoading] = useState(false)
  // const fetchVehicleTypes = async () => {
  //   try {
  //     const data = await getVehicleType(token)
  //     return data
  //   } catch (error) {
  //     console.error('Error fetching vehicle types:', error)
  //     return []
  //   }
  // }
  useEffect(() => {
  
    if (tripId) {
      fetchTripDetails(tripId)
    }
  }, [tripId])

  const fetchTripDetails = async (tripId: string) => {
    try {
      const response = await getTripDetails(tripId, token)
      const parsedPauseTimes = JSON.parse(response.pauseTime || '[]')
    
      setTripDetails(response)
     
      setTripDetailsMileage(response.start_mileage)
      setTripDetailsFaction(response.garbage_types)
      setIsLoad(false)
    } catch (error) {
      console.error('Error fetching trip details:', error)
    }
  }
  AuthChecker()
  const fetchLocationList = async () => {
    try {
      const data = await apiGetLocationList(token)
      return data;
    }
    catch (error) {
      console.error('Error fetching Location:', error);
    }

  }
  const fetchVehicleNumber = async () => {
    try {
      const data = await getVehicleNumber(token)
      return data
    } catch (error) {
      console.error('Error fetching vehicle types:', error)
      return []
    }
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }, [])
  const handleLocationChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value;
    const [postal, ...locParts] = selectedValue.split(' ');
    const loc = locParts.join(' ');
    setPostalCode(postal);
    setLocation(loc);
  };
  const handleMileageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTripDetailsMileage(e.target.value);
  };
  const handleVichleChange = (e: any) => {
    setVehicleNumber(e.target.value);
  };
  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault()
    setLoading(true)
    try {
      const response = await EditedTrip(
        startTime,
        endTime,
        tripDetailsFaction,
        selectedVehicleNumber,
        totalTime,
        JSON.stringify(userId),
        JSON.stringify(tripId),
        token,
        location,
        postalCode,
        tripDetailsMileage
      )
      navigate('/dashboard')
    } catch (error) {
      console.error('Error editing trip:', error)
    } finally {
      setLoading(false)
    }
  }
  const { data: locationList } = useQuery('locationList', fetchLocationList);
  // const {
  //   data: vehicleTypes = [] } = useQuery('vehicleTypes', fetchVehicleTypes, {
  //     onSuccess: (data) => {

  //       const matchedVehicleType = data?.find(

  //         (type: { cost_center_id: number }) =>
  //           type.cost_center_id === state.tripId.cost_center_id
  //       )
  //       if (matchedVehicleType) {

  //         setVehicleType(matchedVehicleType.cost_center_id)
  //       }
  //     }
  //   })
  const { data: vehicleNumber = [] } = useQuery(
    'vehicleNumber',
    fetchVehicleNumber,
    {
      onSuccess: (data) => {
        const matchedVehicleType = data?.find(
          (type: { carId: number }) => type.carId === state.tripId.car_id
        )
        if (matchedVehicleType) {
          setVehicleNumber(matchedVehicleType.carId)
        }
      }
    }
  )
  AuthChecker()

const today = new Date().toISOString().split('T')[0];
  return (
    <>
      <Header style={{paddingTop: "16px", paddingBottom: "16px"}}/>

      {isload?
          <div className="d-flex align-items-center justify-content-center clock" 
          style={{height:"100%",backdropFilter:"blur(1px)"}}>
            <Loader
              type="bubble-top"
              bgColor={'#027a79'}
              color={'#027a79'}
              size={50}
            /></div>:null}
      <div className="mb-3 overFlow p-2">
        <form className="main-container my-2" onSubmit={handleSubmit}>
          <div style={{ position: 'relative', padding: '10px' }}>
            <div
              className="yellow-ribbon-detail"
              style={{ backgroundColor: 'yellowgreen' }}></div>
            <h2 style={{ textAlign: 'center' }} className="mb-2">
              {t('Edit_Trip')}
            </h2>
            <div className="d-flex  justify-content-center flex-column mb-3">
              <h6 className="">{t('startTime')}</h6>
              <input
                type="datetime-local"
                className="inputform"
                style={{ width: '100%', background: 'white' }}
                value={startTime}
                onChange={(e) => setStartTime(formatDateTime(e.target.value))}
                defaultValue={state?.tripId?.check_in_time}
                min={today}
              />
            </div>
            {tripDetails?.category==="Machine Operator"?

                       <div className="d-flex  justify-content-center flex-column  mt-3">
                        <h6 className="">{t('Category')}</h6>
              <input
                type="text"
                className="inputform"
                id="garbage_type"
                value={tripDetails ? tripDetails.category : ''}
                readOnly
                required
                style={{ width: '100%' }}
              />
                        </div>:
                         <div className="d-flex  justify-content-center flex-row inputbox mt-3">
              <div className=" inputWidth">
                        <h6 className="">{t('Category')}</h6>
              <input
                type="text"
                className="inputform"
                id="garbage_type"
                value={tripDetails ? tripDetails.category : ''}
                readOnly
                required
                style={{ width: '100%' }}
              />
                        </div>
                        <div className=" inputWidth">
             <h6 className="">{t('garbageType')}</h6>
            <div className="custom-select">
            <input
                type="text"
                className="inputform"
                id="garbage_type"
                value={tripDetails ? tripDetails?.garbage_types : ''}
                readOnly
                required
                style={{ width: '100%' }}
              />
            
            </div>
            </div>
            </div>}
      {tripDetails?.category==="Machine Operator"||tripDetails?.category==="Loader"?null:
            <div className="d-flex  justify-content-center flex-row inputbox  mt-1">
              <div className=" inputWidth">
                <h6 className="">{t('startMileage')}</h6>
                <input
                  type="number"
                  className="inputform"
                  id="garbage_type"
                  required
                  style={{ width: '100%' }}
                  value={tripDetailsMileage}
                  // defaultValue={tripDetails?.start_mileage}
                  onChange={handleMileageChange}
                />
              </div>
              <div className="inputWidth">
                <h6 className="">{t('VehicleNo')}</h6>
                <div className="custom-select">
                  <select
                    className="inputform"
                    id="serviceDropdown"
                    style={{ background: 'white', padding: "10px 6px" }}
                    name="serviceType"
                    value={selectedVehicleNumber}
                    onChange={(e) => handleVichleChange(e)}
                    required>
                    <option value="">{t('Select_Type')}</option>
                    {Array.isArray(vehicleNumber) &&
                      vehicleNumber.map((type: CarDataType) => (
                        <option key={type.carId} value={type.carId}>
                          {type.carNumber}
                        </option>
                      ))}
                  </select>
                  <span className="custom-arrow"></span>
                </div>
              </div>
            </div>
}
{tripDetails?.category==="Loader"?<div className="d-flex  justify-content-center flex-column mb-3">
  <h6 className="">{t('VehicleNo')}</h6>
                <div className="custom-select">
                  <select
                    className="inputform"
                    id="serviceDropdown"
                    style={{ background: 'white', padding: "10px 6px" }}
                    name="serviceType"
                    value={selectedVehicleNumber}
                    onChange={(e) => setVehicleNumber(e.target.value)}
                    required>
                    <option value="">{t('Select_Type')}</option>
                    {Array.isArray(vehicleNumber) &&
                      vehicleNumber.map((type: CarDataType) => (
                        <option key={type.carId} value={type.carId}>
                          {type.carNumber}
                        </option>
                      ))}
                  </select>
                  <span className="custom-arrow"></span>
                </div></div>:null}
            {/* <div className="d-flex align-items-center justify-content-center flex-row clock">
              <div className=" inputWidth">
                <h6 className="">{t('TotalDist')}</h6>
                <input
                  type="text"
                  className="inputform"
                  style={{ width: '100%' }}
                  defaultValue={state.tripId.kmtotal}
                  value={totalDist}
                  onChange={(e) => setTotalDist(e.target.value)}
                  required
                />
              </div>
              <div className="inputWidth">
                <h6 className="">{t('totalWeight')}</h6>
                <input
                  type="text"
                  className="inputform"
                  style={{ width: '100%' }}
                  value={totalWeight}
                  defaultValue={state.tripId.total_weight}
                  onChange={(e) => setTotalWeight(e.target.value)}
                  required
                />
              </div>
            </div> */}
{tripDetails?.category==="Machine Operator"||tripDetails?.category==="Loader"?null:<>
            <h6 className="">{t('Location_Subtown')}</h6>
            <div className="custom-select">
            <select
              className="inputform"
              id="serviceDropdown"
              name="serviceType"
              style={{ width: '100%', background: 'white',padding: "14px 6px"}}
              value={`${postalCode} ${location}`}
              onChange={handleLocationChange}
              required
            >
              <option value="">{t('Location')}</option>
              {Array.isArray(locationList) &&
                locationList.map((list: any) => (

                  <option key={list.location_id} value={`${list.postal_code} ${list.location}`}>
                    {list.postal_code} {list.location}
                  </option>
                ))}
            </select> <span className="custom-arrow"></span>  </div>
            </>}
            <div className="d-flex align-items-center justify-content-around  mt-3 mb-2 clock">
              {loading ? (
                <Loader
                  type="bubble-top"
                  bgColor={'#027a79'}
                  color={'#027a79'}
                  size={50}
                />
              ) : (
                <Button
                  title={'Update'}
                  backgroundColor={'#027a79'}
                  color={'white'}
                  width={'120px'}
                  onButtonClick={handleSubmit}
                />
              )}
            </div>
          </div>
        </form>
      </div >
    </>
  )
}

export default EditTrip
