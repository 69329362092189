export const BASE_URL = 'https://backend.heilemann-fahrbericht.de/'
export const POST_REQUEST = 'POST'
export const DELETE_REQUEST = 'DELETE'
export const PATCH_REQUEST = 'PATCH'
export const CONTENT_TYPE = 'application/json'
export const Access_Token = 'x-access-token'
export const PUBLIC_TOKEN = 'Logicease123'
export const GET_REQUEST = 'GET'
export const USER_DATA: string = 'user'
export const HEADER = {
    'Content-Type': CONTENT_TYPE,
  }