import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Dropdown from '../components/Dropdown'
import AuthChecker from '../libs/AuthChecker'

const UserLanguage = () => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const { t } = useTranslation()
  useEffect(() => {
    const storedData = localStorage.getItem('user')
    if (storedData) {
      const parsedData = JSON.parse(storedData)
      if (parsedData.user) {
        const user = parsedData.user
        const capitalize = (str: string) =>
          str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
        const firstName = capitalize(user.firstname || '')
        const lastName = capitalize(user.lastname || '')
        setFirstName(firstName)
        setLastName(lastName)
      }
    }
  }, [])
  return (
    <div className="language_bg">
      <div className="user-language-container">
      <h6 className="greeting welcomingText">
  {t('hey')}
  {firstName && lastName ? `, ${firstName} ${lastName} !` : "!"}
</h6>
        <div className="language-selection">
          <h6 className="instruction">{t('select_language')}</h6>
          <Dropdown isLogin={true} />
        </div>
      </div>
    </div>
  )
}

export default UserLanguage
