import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage'
import { storage } from '../libs/firebase'

const useFileUpload = () => {
  const UploadFile = async (file: Blob, filename: string) => {
    if (!file) return

    const storageRef = ref(storage, `file/${'' + filename}`)
    const uploadTask = uploadBytesResumable(storageRef, file)

    return new Promise((resolve, reject) => {
      uploadTask.on(
        'state_changed',
        () => {
        },
        (error) => {
          reject(error)
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref)
            resolve(downloadURL)
          } catch (error) {
            reject(error)
          }
        }
      )
    })
  }

  return { UploadFile }
}

export default useFileUpload
