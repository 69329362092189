import React, {
  Reducer,
  createContext,
  useReducer,
  useEffect,
  ReactNode,
  Dispatch
} from 'react'
import { UserType } from '../types/global';

type AuthAction = { type: 'LOGIN'; user: UserType } | { type: 'LOGOUT' }

type AuthState = {
  user: UserType | null
}

type AuthContextType = {
  state: AuthState
  dispatch: Dispatch<AuthAction>
}

export const AuthContext = createContext<AuthContextType>({
  state: { user: null },
  dispatch: () => null
})

const authReducer: Reducer<{ user: UserType | null }, AuthAction> = (
  state,
  action
) => {
  switch (action.type) {
    case 'LOGIN':
      return { user: action.user }
    case 'LOGOUT':
      return { user: null }
    default:
      return state
  }
}

export const AuthContextProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(authReducer, {
    user: null
  })
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user') || 'null')
    if (user) {
      dispatch({ type: 'LOGIN', user: user })
    }
  }, [])

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthContext.Provider>
  )
}
