import React, { useState, useEffect } from 'react'
import './App.css'
import { BrowserRouter } from 'react-router-dom'
import RoutesComponents from './components/RoutesComponents'
import './styles/Public.css'
import './styles/Responsiveness.css'
import LanguageContext from './context/LanguageContext'
import { initReactI18next } from 'react-i18next/initReactI18next'
import en from './translations/en.json'
import ger from './translations/ger.json'
import pol from './translations/pol.json'
import ru from './translations/ru.json'
import tur from './translations/tur.json'
import i18n from 'i18next'
import { AuthContextProvider } from './context/authContext'

i18n.use(initReactI18next).init({
  resources: {
    en,
    ger,
    pol,
    ru,
    tur
  },
  lng: localStorage.getItem('selectedLanguage') || 'en',
  fallbackLng: 'en',

  interpolation: {
    escapeValue: false
  }
})

function App() {
  const [language, setLanguage] = useState(i18n.language)

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang)
    setLanguage(lang)
    localStorage.setItem('selectedLanguage', lang)
  }

  useEffect(() => {
    setLanguage(i18n.language)
  }, [i18n.language])
  
  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      <AuthContextProvider>
        <BrowserRouter>
          <RoutesComponents />
        </BrowserRouter>
      </AuthContextProvider>
    </LanguageContext.Provider>
  )
}

export default App
