import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from './Button';
import SelectImage from './SelectImage';
import Modal from './Modal';
import Webcam from 'react-webcam'


interface StepDamageQuestionProps {
  setDamagedPicture: (path: string) => void;
  imageUri: (path: string) => void;
  onNext: () => void;
}

const StepDamageQuestion: React.FC<StepDamageQuestionProps> = ({
  setDamagedPicture,
  imageUri,
  onNext
}) => {
  const { t } = useTranslation();
  const webcamRef = useRef<Webcam>(null)
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [damaged, setDamagedState] = useState(false);
  const [noDamaged, setNoDamagedState] = useState(false);
  const [showSelectImage, setShowSelectImage] = useState(false);
  const [showWebcam, setShowWebcam] = useState<boolean>(false);
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
 
  const [imgSrc, setImgSrc] = useState<string | null>(null)
  const handleYesClick = () => {
    setDamagedState(true);
    setShowSelectImage(true);
  };

  const handleNoClick = () => {
    setNoDamagedState(true);
    onNext();
  };
  
  const handleCapture = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (isMobile) {
      const input = document.createElement('input')
      input.type = 'file'
      input.accept = 'image/*'
      input.capture = 'environment'
      input.onchange = (event) => {
        const file = (event.target as HTMLInputElement).files?.[0]
        if (file) {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onloadend = () => {
            const selectedImageSrc = reader.result as string
            imageUri(selectedImageSrc)
            setSelectedImage(selectedImageSrc)
          }
        } else {
          console.error('No file selected or file input is null')
        }
      }
      input.onerror = (error) => {
        console.error('Error accessing camera or selecting file:', error)
      }
      input.click()
    }
    else {
      if (webcamRef.current) {
        try {
          const imageSrc = webcamRef.current.getScreenshot();
          if (imageSrc) {
            setImgSrc(imageSrc);
            imageUri(imageSrc);
            setSelectedImage(imageSrc)
            setShowSelectImage(false)
            setShowWebcam(false); // Hide webcam after capture
          } else {
            console.error('Failed to capture image: screenshot is null');
          }
        } catch (error) {
          console.error('Error capturing image from webcam:', error);
        }
      } else {
        console.error('Webcam reference is null or undefined');
      }}
    };

  const handleImageSelection = (imageUri: string) => {
    setSelectedImage(imageUri);
    setDamagedPicture(imageUri);
    setShowSelectImage(false);
  };

  return (
    <div className="d-flex align-items-center justify-content-center m-auto height">
      <form className="main-container">
        <div className="userHeading" style={{background: "#EC5800"}}>
          <h2 className="textcolor">{t('Damaged')}</h2>
        </div>
        <div className='p-3'>
          {!damaged && !noDamaged ? (
            <>
              {/* <h6 className="text-center mb-3">{t('Was_damage')}</h6> */}
              <div className="d-flex flex-column align-items-center" style={{ gap: '10px' }}>
                <div className="d-flex align-items-center flex-row justify-content-center gap-4 mt-3">
                  <div className="choose-container" style={{background:"#EC5800"}}>
                    <p onClick={handleYesClick}>{t('Yes')}</p>
                  </div>
                  <div className="choose-container"style={{background:"#EC5800"}}>
                    <p onClick={handleNoClick}>{t('No')}</p>
                  </div>
                </div>
              </div>
            </>
          ) : damaged && !noDamaged ? (
            <div className="d-flex align-items-center justify-content-center flex-column">
              {selectedImage && (
                <img
                  src={selectedImage}
                  alt="Selected"
                  style={{ maxWidth: '100%', maxHeight: '200px' }}
                />
              )}
              {showSelectImage && (
                <Modal onClose={() => setShowSelectImage(false)}>
                  <SelectImage
                    imageUri={handleImageSelection}
                    imgSrc={imgSrc}
                    showWebcam={(e:any)=>{ 
                      setShowSelectImage(false)
                     if(isMobile){
                      handleCapture(e)
                     }else{setShowWebcam(true)}}}
                    closeModal={() => setShowSelectImage(false)
                      }
                      
                    isShowModal={showSelectImage}
                  />
                </Modal>
              )}
              {showWebcam&&
              <div style={{ width: '100%', height: '400px' }}>
                  <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    width="100%"  
                    height="400px" 
                    videoConstraints={{ facingMode: "user" }}
                    onUserMedia={() => console.log('Webcam activated!')}
                    onUserMediaError={(error) => console.error('Webcam error:', error)}
                  />
                </div>}
              <div className="d-flex mt-3 clock1">
              {showWebcam?<Button
                  onButtonClick={handleCapture}
                  title={t('Capture')}
                  backgroundColor="#EC5800"
                  color="white"
                />:
                <Button
                  onButtonClick={() => {setShowSelectImage(true)}}
                  title={t('Select_Image')}
                  backgroundColor="#EC5800"
                  color="white"
                />}
                
                <Button
                  onButtonClick={onNext}
                  title={t('Next')}
                  backgroundColor={selectedImage ? '#EC5800' : '#ccc'}
                  color="white"
                  width="120px"
                  disabled={!selectedImage}
                />
              </div>
            </div>
          ) : null}
        </div>
      </form>
    </div>
  );
};

export default StepDamageQuestion;
