import { faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type ClockProps = {
  tripId?: any;  // Each trip has its own ID
  startTime?: string;  // Start time for each trip
};

const Clock: React.FC<ClockProps> = ({ tripId, startTime }) => {
  const { t } = useTranslation();

  // Create a unique key for each trip using its ID
  const getLocalStorageKey = (tripId: number) => `persistedStartTime_${tripId}`;

  // Initialize the clock based on localStorage or the startTime passed as a prop
  const initializeClock = () => {
    const startMoment = moment(startTime, 'HH:mm:ss');
    const persistedTime = localStorage.getItem(getLocalStorageKey(tripId));
    
    // If there's persisted time for the specific trip, use it; otherwise, use startTime
    return persistedTime ? moment(persistedTime, 'HH:mm:ss') : startMoment;
  };

  const [currentTime, setCurrentTime] = useState(initializeClock);

  useEffect(() => {
    const startMoment = moment(startTime, 'HH:mm:ss');
    
    const intervalId = setInterval(() => {
      setCurrentTime(prevTime => {
        const secondsElapsed:any =  moment().diff(startMoment, 'seconds');
        const newTime = moment(startTime, 'HH:mm:ss').add(secondsElapsed, 'seconds');
        console.log("ejfv",moment(startTime).format("HH:mm:ss"))
        
        localStorage.setItem(getLocalStorageKey(tripId), newTime.format('HH:mm:ss'));
        return newTime;
      });
    }, 1000);

    return () => clearInterval(intervalId); 
  }, [startTime, tripId]); 

  const formattedTime = currentTime.format('HH:mm:ss');

  return (
    <div className="d-flex align-items-center flex-row clockTime">
      <FontAwesomeIcon
        icon={faClock}
        color={'orange'}
        style={{ marginRight: '10px' }}
      />
      <p>
        {t('timeText')}
        {formattedTime}  
      </p>
    </div>
  );
};

export default Clock;
