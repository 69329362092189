

export const getDataFromLocal = (key: string) => {
  const getData: string | null = localStorage.getItem(key)
  if (getData !== null) {
    const user = JSON.parse(getData)
    return user
  }
  return null
}
